import React, {useCallback, useEffect} from "react";
import {RightContainer as Container} from "../../styles/GlobalStyles";
import {useStateContext} from "../_StateHandler/StateContenxt";
import {STATES} from "../../utils/constants";
import {redirectTo} from "../../utils/url";
import {createSamlCookie} from "../../utils/token";
import {FormInfo} from "../Components";

const SamlLoggedIn = () => {
  const {setState} = useStateContext();


  const setSamlToken = useCallback(async () => {
    try {
      createSamlCookie();
      redirectTo(); //Empty means "redirect to academy"
    } catch (err) {
      console.error(err);
      setState(STATES.SIGN_IN);
    }
  }, [setState]);

  useEffect(() => {
    setSamlToken();
  }, [setSamlToken]);

  return (
    <Container>
      <FormInfo title='Redirecionando...' description='Usuário logado'/>
    </Container>
  );
};

export default SamlLoggedIn;