import React, { useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { RightContainer as Container } from "../../styles/GlobalStyles";
import { useStateContext } from "../_StateHandler/StateContenxt";
import { STATES } from "../../utils/constants";

import { FormInfo } from "../Components";

const UserConfirmed = () => {
  const { setState, inputs } = useStateContext();

  const signConfirmedUser = useCallback(async () => {
    try {
      await Auth.signIn(inputs.email, inputs.password);
      setState(STATES.SIGNED_IN);
    } catch (err) {
      console.error(err);
      setState(STATES.SIGN_IN);
    }
  }, [inputs, setState]);

  useEffect(() => {
    signConfirmedUser();
  }, [signConfirmedUser]);

  return (
    <Container>
      <FormInfo title='Redirecionando...' description='Usuário confirmado' />
    </Container>
  );
};

export default UserConfirmed;
