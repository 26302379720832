import React from "react";
import Amplify, { I18n } from "aws-amplify";
import awsconfig from "./aws-exports";
import { dict } from "./settings/vocabularies";
import { NotificationProvider } from "./components/Components";
import GlobalStyles from "./styles/GlobalStyles";
import Routes from "./routes";

Amplify.configure(awsconfig);
I18n.setLanguage("pt_BR");
I18n.putVocabularies(dict);

const App = () => {
  return (
    <>
      <NotificationProvider>
        <Routes />
      </NotificationProvider>
      <GlobalStyles />
    </>
  );
};

export default App;
