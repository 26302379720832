import React from "react";
import { ButtonTransparent, Button } from "../../../../styles/GlobalStyles";

const ButtonSubmit = ({ transparent, title, fontSize, textTransform }) => {
  return (
    <>
      {transparent ? (
        <ButtonTransparent
          type='submit'
          fontSize={fontSize}
          textTransform={textTransform}
        >
          {title || ""}
        </ButtonTransparent>
      ) : (
        <Button type='submit' fontSize={fontSize} textTransform={textTransform}>
          {title || ""}
        </Button>
      )}
    </>
  );
};

export default ButtonSubmit;
