import React from "react";
import {
  Section,
  Page,
  PageBackground,
  SubTitle,
} from "../../styles/GlobalStyles";
import { LogoCard, ConfirmSignUp } from "../../components/Components";

const ConfirmSignUpPage = () => {
  return (
    <Page>
      <Section>
        <LogoCard>
          <SubTitle>Traçando um novo futuro.</SubTitle>
        </LogoCard>
        <ConfirmSignUp />
      </Section>
      <PageBackground />
    </Page>
  );
};

export default ConfirmSignUpPage;
