import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { STATES } from "./utils/constants";
import { StateHandler } from "./components/Components";
import {
  SignInPage,
  // SignUpPage,
  SignedInPage,
  ConfirmSignUpPage,
  UserConfirmedPage,
  CompleteNewPasswordPage,
  ForgotPasswordPage,
  ForgotPasswordSubmitPage,
  LogoutPage,
  SamlLoggedInPage,
} from "./pages/Pages";

const Routes = () => {
  return (
    <Router>
      <StateHandler>
        <Switch>
          <Route exact path='/' component={SignInPage} />
          <Route exact path={`/${STATES.SIGN_IN}`} component={SignInPage} />
          {/* <Route exact path={`/${STATES.SIGN_UP}`} component={SignUpPage} /> */}
          <Route exact path={`/${STATES.SIGNED_IN}`} component={SignedInPage} />
          <Route exact path={`/${STATES.SAML_LOGGED_IN}`} component={SamlLoggedInPage} />
          <Route exact path={`/${STATES.LOGOUT}`} component={LogoutPage} />
          <Route exact path={`/admin/logout`} component={LogoutPage} />
          <Route
            exact
            path={`/${STATES.CONFIRM_SIGN_UP}`}
            component={ConfirmSignUpPage}
          />
          <Route
            exact
            path={`/${STATES.USER_CONFIRMED}`}
            component={UserConfirmedPage}
          />
          <Route
            exact
            path={`/${STATES.COMPLETE_NEW_PASSWORD}`}
            component={CompleteNewPasswordPage}
          />
          <Route
            exact
            path={`/${STATES.FORGOT_PASSWORD}`}
            component={ForgotPasswordPage}
          />
          <Route
            exact
            path={`/${STATES.FORGOT_PASSWORD_SUBMIT}`}
            component={ForgotPasswordSubmitPage}
          />
        </Switch>
      </StateHandler>
    </Router>
  );
};

export default Routes;
