import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Info = styled.div`
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 22px;

  @media screen and (max-width: 575px) {
    padding-bottom: 10px;
  }
`;

export const Title = styled.h2`
  color: #333;
  font-size: 19px;
  font-weight: bold;
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 22px;

  @media screen and (max-width: 575px) {
    font-size: 14px;
  }
`;
