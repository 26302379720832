import React from "react";
import { Container, Logo } from "./styles";
import LogoUrl from "../../../assets/images/logo.png";

const LogoCard = ({ children }) => {
  return (
    <Container>
      <Logo src={LogoUrl} alt='Logo' />
      {children}
    </Container>
  );
};

export default LogoCard;
