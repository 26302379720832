import React from "react";
import { Span } from "../../../../styles/GlobalStyles";
import { useStateContext } from "../../../_StateHandler/StateContenxt";
import { STATES } from "../../../../utils/constants";

const SpanBackToLogin = ({
  title,
  textColor,
  fontSize,
  margin,
  textDecoration,
}) => {
  const { setState } = useStateContext();

  return (
    <Span
      textColor={textColor}
      fontSize={fontSize}
      margin={margin}
      textDecoration={textDecoration || "underline"}
      onClick={(e) => setState(STATES.SIGN_IN)}
    >
      {title || "Voltar para o login"}
    </Span>
  );
};

export default SpanBackToLogin;
