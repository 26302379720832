import { Auth } from "aws-amplify";
import { STATES, EXCEPTIONS } from "./constants";
import Cookie from "js-cookie";

export const setCognitoCookies = (cookies) => {
  const setCookie = (key, value) => {
    if (window.location.origin.indexOf("localhost")) {
      Cookie.set(key || process.env.REACT_APP_TOKEN, value);
    }
    Cookie.set(key || process.env.REACT_APP_TOKEN, value, {
      domain: process.env.REACT_APP_ACADEMY_URL,
    });
  };

  Object.keys(cookies).forEach((key) => {
    if (key.includes("CognitoIdentityServiceProvider")) {
      const splitedKey = key.split(".");
      const newKey = `${splitedKey[splitedKey.length - 1]}-splitedKey`;
      const newValue = `${key}&separator&${cookies[key]}`;

      setCookie(newKey, newValue);
    } else if (key.includes("cognito")) {
      const splitedKey = key.split(".");
      const newKey = `${splitedKey[2]}-splitedKey`;
      const newValue = `${key}&separator&${cookies[key]}`;

      setCookie(newKey, newValue);
    } else {
      setCookie(key, cookies[key]);
    }
  });
  setCookie("auth-sign", true);
};

export const isNewPasswordRequired = (challengeName) => {
  return challengeName === STATES.NEW_PASSWORD_REQUIRED;
};

export const isUserNotConfirmed = (code) => {
  return code === EXCEPTIONS.USER_NOT_CONFIRMED;
};

export const getCurrentAuthenticatedUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getCurrentSession = async () => {
  try {
    return await Auth.currentSession();
  } catch (err) {
    console.error(err);
  }
};
