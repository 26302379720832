export const STATES = {
  SIGN_IN: "login",
  SIGN_UP: "signUp",
  SIGNED_IN: "signedIn",
  SAML_LOGGED_IN: "samlLoggedIn",
  CONFIRM_SIGN_IN: "confirmSignIn",
  CONFIRM_SIGN_UP: "confirmSignUp",
  USER_CONFIRMED: "userConfirmed",
  FORGOT_PASSWORD: "forgotPassword",
  FORGOT_PASSWORD_SUBMIT: "forgotPasswordSubmit",
  CHANGE_PASSWORD: "changePassword",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  COMPLETE_NEW_PASSWORD: "completeNewPassword",
  LOGOUT: "logout",
  RENEW: "renew",
  REFRESH: "refresh",
};

export const INPUTS = {
  USERNAME: "username",
  PASSWORD: "password",
  PASSWORD_CONFIRM: "passwordConfirm",
  EMAIL: "email",
  NAME: "name",
  PHONE_NUMBER: "phone_number",
  OLD_PASSWORD: "oldPassword",
  VERIFICATION_CODE: "verificationCode",
};

export const EXCEPTIONS = {
  USER_NOT_CONFIRMED: "UserNotConfirmedException",
};
