import React from "react";
import { hasEmailParam, redirectToSamlLogin } from "../../utils/url";
import {
  Section,
  Page,
  PageBackground,
  SubTitle,
} from "../../styles/GlobalStyles";
import { LogoCard, SignIn } from "../../components/Components";

const SignInPage = () => {
  if (!hasEmailParam()) {
    redirectToSamlLogin();
    return null;
  }

  return (
    <Page>
      <Section>
        <LogoCard>
          <SubTitle>Traçando um novo futuro.</SubTitle>
        </LogoCard>
        <SignIn />
      </Section>
      <PageBackground />
    </Page>
  );
};

export default SignInPage;
