import Cookie from "js-cookie";
import {redirectToLogin} from "./url";

export const createCookie = (value) => {
  if (!value) {
    redirectToLogin();
  }

  if (window.location.origin.includes("localhost")) {
    Cookie.set(process.env.REACT_APP_TOKEN, value);
  }

  Cookie.set(process.env.REACT_APP_TOKEN, value, {
    domain: process.env.REACT_APP_DOMAIN,
  });
};

export const createSamlCookie = () => {
  let authToken = getParameterByName("id_token");
  let cognitoError = getParameterByName("error");

  if (cognitoError) {
    document.write(getParameterByName("error_description"));
    return;
  }

  if (window.location.origin.indexOf('localhost')) {
    Cookie.set(process.env.REACT_APP_TOKEN, authToken);
  }

  Cookie.set(process.env.REACT_APP_TOKEN, authToken, {
    domain: process.env.REACT_APP_DOMAIN
  });
};

export const clearCookie = () => {
  localStorage.clear();
  sessionStorage.clear();
  if (window.location.origin.indexOf("localhost") !== -1) {
    Cookie.remove(process.env.REACT_APP_TOKEN);
  }

  Cookie.remove(process.env.REACT_APP_TOKEN, {
    domain: process.env.REACT_APP_DOMAIN,
  });
  Object.keys(Cookie.get()).forEach((item) => {
    Cookie.remove(item, {
      domain: process.env.REACT_APP_DOMAIN,
    });
  });
};

export const getParameterByName = (name) => {
  const url = window.location.href;
  console.log("url: " + window.location.href);

  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?#&]" + name + "(=([^&#]*)|&|#|$)");
  let results = regex.exec(url);
  console.log("results: " + results);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

