import React, { useEffect, useCallback } from "react";
import { RightContainer as Container } from "../../styles/GlobalStyles";
import { useStateContext } from "../_StateHandler/StateContenxt";
import { STATES } from "../../utils/constants";
import { getCurrentSession } from "../../utils/auth";
import { getUrlOrigin, redirectTo } from "../../utils/url";
import { createCookie } from "../../utils/token";
import { FormInfo } from "../Components";

const SignedIn = () => {
  const { setState } = useStateContext();

  const setToken = useCallback(async () => {
    try {
      const currentSession = await getCurrentSession();

      if (currentSession && currentSession.idToken) {
        createCookie(currentSession.idToken.jwtToken);
        redirectTo(getUrlOrigin());
      } else {
        setState(STATES.LOGOUT);
      }
    } catch (err) {
      console.error(err);
      setState(STATES.SIGN_IN);
    }
  }, [setState]);

  useEffect(() => {
    setToken();
  }, [setToken]);

  return (
    <Container>
      <FormInfo title='Redirecionando...' description='Usuário logado' />
    </Container>
  );
};

export default SignedIn;
