import React from "react";
import { Span } from "../../../../styles/GlobalStyles";
import { useStateContext } from "../../../_StateHandler/StateContenxt";
import { useNotificationContext } from "../../../UtilComponents/Notification/NotificationProvider";
import { Auth } from "aws-amplify";

const SpanResendCode = ({
  title,
  textColor,
  fontSize,
  margin,
  textDecoration,
}) => {
  const { inputs } = useStateContext();
  const { createNotification } = useNotificationContext();

  const handleClick = async (e) => {
    e.preventDefault();

    try {
      await Auth.resendSignUp(inputs.email);
      createNotification({
        type: "success",
        message: "Código enviado com sucesso.",
        time: 5000,
      });
    } catch (err) {
      console.error(err.message);
      createNotification({ type: "error", message: err.message, time: 5000 });
    }
  };

  return (
    <Span
      textColor={textColor}
      fontSize={fontSize}
      margin={margin}
      textDecoration={textDecoration || "underline"}
      onClick={(e) => handleClick(e)}
    >
      {title || "Reenviar código"}
    </Span>
  );
};

export default SpanResendCode;
