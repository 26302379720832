import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getRouteParam, removeFirstUrlParam } from "../../utils/url";
import { StateContext } from "./StateContenxt";

const StateHandler = ({ children }) => {
  const history = useHistory();
  const [state, setState] = useState("");
  const [user, setUser] = useState({});
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
    passwordConfirm: "",
    email: "",
    name: "",
    phone_number: "",
    oldPassword: "",
    verificationCode: "",
  });

  const updateInputs = (input) => {
    if (input) {
      setInputs({ ...inputs, [input.target.name]: input.target.value });
    }
  };

  useEffect(() => {
    const currentUrlParams = history.location.search;
    const hasRouteParam = getRouteParam();
    let route = state;
    let params = currentUrlParams;

    if (hasRouteParam) {
      route = hasRouteParam;
      params = removeFirstUrlParam(currentUrlParams);
    }

    route && history.push(`/${route}${params}`);
  }, [state, history]);

  return (
    <StateContext.Provider
      value={{ state, setState, user, setUser, inputs, updateInputs }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateHandler;
