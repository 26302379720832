import styled, { createGlobalStyle } from "styled-components";
import BackgroundImage from "../assets/images/signin-background.png";

export default createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
}

*, button, input {
    border: 0;
    outline: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

:root {
    --primary-color: #00205b;
    --secondary-color: #fc4c02;
}

`;

export const Page = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageBackground = styled.div`
  z-index: -1;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: linear-gradient(
      rgba(0, 32, 91, 0.43),
      rgba(0, 32, 91, 0.43)
    ),
    url(${({ url }) => url || BackgroundImage});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Section = styled.section`
  width: 800px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 3px 0 8px -1px #01040a;

  @media screen and (max-width: 991px) {
    width: 700px;
  }

  @media screen and (max-width: 768px) {
    width: 400px;
    height: 600px;
    flex-direction: column;
  }

  @media screen and (max-width: 575px) {
    width: 95%;
    height: 80%;
  }
`;

export const LeftContainer = styled.div`
  margin: 0 auto 0 0;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px 0 0 6px;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 45%;
    border-radius: 6px 6px 0 0;
  }
`;

export const RightContainer = styled.div`
  margin: 0 0 0 auto;
  padding: 30px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 6px 6px 0;
  background-color: white;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 55%;
    border-radius: 0 0 6px 6px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  margin: 0 0 15px 0;
  padding: 20px;
  width: 260px;
  height: 40px;

  border: 1px solid #cccccc;
  background-color: #f5f5f5;
  border-radius: 6px;
  border-style: none;

  color: #0e0e0e;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;

  &:focus,
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const Button = styled.button`
  margin: 0 0 25px 0;
  width: 264px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  border-radius: 6px;
  background-color: var(--secondary-color);

  cursor: pointer;

  transition: transform 500ms ease;
  &:hover {
    transform: translate(0px, -3px);
  }
`;

export const ButtonTransparent = styled(Button)`
  background-color: transparent;
  border: 1px solid ${({ borderColor }) => borderColor || "#fff"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  text-transform: ${({ textTransform }) => textTransform || "uppercase"};

  &:hover {
    background-color: var(--secondary-color);
    border: none;
  }
`;

export const SpansContainer = styled.div`
  padding: 0 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
`;

export const Span = styled.span`
  margin: 0 0 10px;
  font-size: 12px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
`;

export const SubTitle = styled.h2`
  padding-top: 20px;
  color: rgb(51, 51, 51);
  font-size: 22px;
  font-weight: 900;
  line-height: 20px;
  text-align: center;
`;

export const TitleAdmin = styled.h5`
  width: 100%;
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 22px;
  text-align: center;
  @media screen and (max-width: 768px) {
    position: fixed;
    top: 5px;
    color: #fff;
  }
  @media screen and (max-width: 575px) {
    font-size: 18px;
  }
`;
