import React from "react";
import {
  Section,
  Page,
  PageBackground,
  SubTitle,
} from "../../styles/GlobalStyles";
import { LogoCard, UserConfirmed } from "../../components/Components";

const UserConfirmedPage = () => {
  return (
    <Page>
      <Section>
        <LogoCard>
          <SubTitle>Traçando um novo futuro.</SubTitle>
        </LogoCard>
        <UserConfirmed />
      </Section>
      <PageBackground />
    </Page>
  );
};

export default UserConfirmedPage;
