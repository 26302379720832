import { STATES } from "./constants";
import awsmobile from "../aws-exports";

export const getRouteParam = () => {
  const urlRoute = getUrlRoute();

  switch (true) {
    case urlRoute.includes("acesse-sua-conta") || urlRoute.includes("login"):
      return STATES.SIGN_IN;
    case urlRoute.includes("seja-um-membro"):
      return STATES.SIGN_UP;
    case urlRoute.includes("logout") || urlRoute.includes("logoutRedirect"):
      return STATES.LOGOUT;
    case urlRoute.includes("renew"):
      return STATES.RENEW;
    case urlRoute.includes("recuperar-senha"):
      return STATES.FORGOT_PASSWORD_SUBMIT;
    default:
      return "";
  }
};

export const removeFirstUrlParam = (urlParams) => {
  let params = [];

  urlParams.split("&").forEach((param, index) => {
    if (index === 1) {
      params += `?${param}`;
    } else if (index >= 2) {
      params += `&${param}`;
    }
  });

  return params;
};

export const getUrlRoute = () => {
  try {
    const { route } = handleUrlParams();
    return route;
  } catch (err) {
    console.error(err);
    return "";
  }
};

export const getUrlOrigin = () => {
  try {
    const { origin } = handleUrlParams();
    const redirect = origin.length && origin[0] ? atob(origin[0]) : "";
    return hasAdmin() ? process.env.REACT_APP_ADMIN_URL : redirect;
  } catch (err) {
    console.error(err);
    return "";
  }
};

export const getUrlMsg = () => {
  try {
    const { msg } = handleUrlParams();
    return msg;
  } catch (err) {
    console.error(err);
    return "";
  }
};

export const handleUrlParams = () => {
  const params = window.location.search.split(/[?=]+/);
  const route = params[1] || "";
  const origin = params[2] ? params[2].split(/[&=]+/) : "";
  const msg = params[3] || "";

  return { route, origin, msg };
};

export const isValidState = (state) => {
  if (state) {
    const validStates = Object.values(STATES);
    const isValid = validStates.find(
      (validState) => validState.toLowerCase() === state.toLowerCase()
    );
    return isValid;
  }

  return false;
};

export const hasAdmin = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("adm") === "true";
};

export const getCodeParam = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("code");
};

export const getEmailParam = () => {
  return window.location.search.split("email=")[1];
};

export const hasEmailParam = () => {
  return window.location.search.includes("email=");
};

export const redirectTo = (redirect = "") => {
  if (redirect) {
    window.location.href = redirect;
  } else if (hasAdmin()) {
    window.location.href = `${process.env.REACT_APP_ADMIN_URL}`;
  } else {
    window.location.href = `${process.env.REACT_APP_ACADEMY_URL}`;
  }
};

export const redirectToLogin = (redirect = "") => {
  if (redirect) {
    if (hasAdmin()) {
      window.location.href = `/login?${btoa(redirect)}&adm=true`;
    } else {
      window.location.href = `/login?${btoa(redirect)}`;
    }
  } else {
    if (hasAdmin()) {
      window.location.href = `/login?${btoa(
        `${process.env.REACT_APP_ADMIN_URL}`
      )}&adm=true`;
    } else {
      window.location.href = `/login?${btoa(
        `${process.env.REACT_APP_ACADEMY_URL}`
      )}`;
    }
  }
};

export const redirectToSamlLogin = () => {
  const signinUri = `${process.env.REACT_APP_SIGNIN_URL}/samlLoggedIn`;
  const loginUri = `https://${process.env.REACT_APP_SAML_DOMAIN}/login?client_id=${awsmobile.aws_user_pools_web_client_id}&response_type=token&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${signinUri}`;

  window.location = loginUri;
};
