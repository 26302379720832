import React from "react";
import {
  Section,
  Page,
  PageBackground,
  SubTitle,
} from "../../styles/GlobalStyles";
import { LogoCard, SignedIn } from "../../components/Components";

const SignedInPage = () => {
  return (
    <Page>
      <Section>
        <LogoCard>
          <SubTitle>Traçando um novo futuro.</SubTitle>
        </LogoCard>
        <SignedIn />
      </Section>
      <PageBackground />
    </Page>
  );
};

export default SignedInPage;
