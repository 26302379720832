import React from "react";
import {Page, PageBackground, Section, SubTitle,} from "../../styles/GlobalStyles";
import {LogoCard, SamlLoggedIn} from "../../components/Components";

const SamlLoggedInPage = () => {
  return (
    <Page>
      <Section>
        <LogoCard>
          <SubTitle>Traçando um novo futuro.</SubTitle>
        </LogoCard>
        <SamlLoggedIn />
      </Section>
      <PageBackground />
    </Page>
  );
};

export default SamlLoggedInPage;
