import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Icon = styled.i`
  position: absolute;
  top: 25%;
  right: 5%;
  cursor: pointer;
`;
