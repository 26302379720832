import React from "react";
import { ClipLoader } from "react-spinners";
import { Container } from "./styles";

const Spinner = ({ size, color }) => {
  const DEFAULT_SIZE = 50;
  const DEFAULT_COLOR = "#2F276C";

  return (
    <Container>
      <ClipLoader size={size || DEFAULT_SIZE} color={color || DEFAULT_COLOR} />
    </Container>
  );
};

export default Spinner;
