import React, { useState } from "react";
import { Container, Icon } from "./styles";
import { Input } from "../../../../styles/GlobalStyles";
import { INPUTS } from "../../../../utils/constants";
import { useStateContext } from "../../../_StateHandler/StateContenxt";

const InputPassword = ({
  placeholder,
  minlength,
  required,
  hasVisibilityToggle,
}) => {
  const { updateInputs } = useStateContext();
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = (e) => {
    e.preventDefault();
    setIsVisible((prev) => !prev);
  };

  return (
    <Container>
      <Input
        name={INPUTS.PASSWORD}
        type={isVisible ? "text" : INPUTS.PASSWORD}
        placeholder={placeholder || ""}
        minLength={minlength || 6}
        required={required || true}
        onChange={(e) => updateInputs(e)}
      />
      {hasVisibilityToggle && (
        <Icon
          className={`far fa-eye${!isVisible ? "-slash" : ""}`}
          onClick={(e) => toggleVisibility(e)}
        />
      )}
    </Container>
  );
};

export default InputPassword;
