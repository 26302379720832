import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { RightContainer as Container, Form } from "../../styles/GlobalStyles";
import { useStateContext } from "../_StateHandler/StateContenxt";
import { useNotificationContext } from "../UtilComponents/Notification/NotificationProvider";
import { STATES } from "../../utils/constants";
import {
  FormInfo,
  InputEmail,
  InputPassword,
  ButtonSubmit,
  SpanBackToLogin,
  Spinner,
} from "../Components";

const CompleteNewPassword = () => {
  const { setState, user, inputs } = useStateContext();
  const { createNotification } = useNotificationContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await Auth.completeNewPassword(user, inputs.password);
      createNotification({
        type: "success",
        message: "Senha alterada com sucesso.",
        time: 5000,
      });
      setState(STATES.SIGNED_IN);
    } catch (err) {
      console.error(err.message);
      createNotification({ type: "error", message: err.message, time: 5000 });
    }

    setIsLoading(false);
  };

  return (
    <Container>
      <FormInfo description='Para continuar, é necessário que você atualize sua senha.' />
      <Form onSubmit={(e) => handleSubmit(e)}>
        <InputEmail placeholder='E-mail' defaultValue={inputs.email} required />
        <InputPassword placeholder='Nova senha' required hasVisibilityToggle />
        <ButtonSubmit
          title={
            isLoading ? <Spinner size={20} color='#fff' /> : "Trocar a senha"
          }
        />
      </Form>
      <SpanBackToLogin title='Voltar para o login' />
    </Container>
  );
};

export default CompleteNewPassword;
