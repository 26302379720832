import React from "react";
import { Container, Info, Description, Title } from "./styles";

const FormInfo = ({ title, description }) => {
  return (
    <Container>
      <Info>
        {description && <Description>{description}</Description>}
        {title && <Title>{title}</Title>}
      </Info>
    </Container>
  );
};

export default FormInfo;
