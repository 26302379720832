import React, { useEffect, useCallback } from "react";
import { RightContainer as Container } from "../../styles/GlobalStyles";
import { useStateContext } from "../_StateHandler/StateContenxt";
import { STATES } from "../../utils/constants";
import { clearCookie } from "../../utils/token";
import { FormInfo } from "../Components";
import { Auth } from "aws-amplify";

const Logout = () => {
  const { setState } = useStateContext();

  const signOut = useCallback(async () => {
    try {
      localStorage.clear();
      clearCookie();
      await Auth.signOut();

      setState(STATES.SIGN_IN);
    } catch (err) {
      console.error(err);
      setState(STATES.SIGN_IN);
    }
  }, [setState]);

  useEffect(() => {
    signOut();
  }, [signOut]);

  return (
    <Container>
      <FormInfo title='Redirecionando...' description='Usuário deslogado' />
    </Container>
  );
};

export default Logout;
